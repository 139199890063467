import React, { Component } from 'react';
import LoadingBig from '$common/LoadingBig';
import RegionRedirect from '$utils/middleware/RegionRedirect';

class Init extends Component {
  constructor() {
    super();
    this.state = {
      Auth: LoadingBig,
    };
    require.ensure(
      ['./Auth'],
      (require) => {
        const Auth = require('./Auth').default;
        this.setState({ Auth });
      },
      'auth'
    );
  }

  render() {
    const { Auth } = this.state;

    // rewrite APP_URL to resolve below issue
    // AU and NZ are both under the same NODE_ENV=production-au
    // But AU should use 'app.becollective.com' and NZ should use 'nz.becollective.com' when generate links
    // 'https://app.becollective.com/'.replace(/(https:\/\/)(app)/,'$1nz')
    // => 'https://nz.becollective.com/'
    if (window.location.hostname === 'nz.becollective.com') {
      window.APP_URL = APP_URL.replace(/(https:\/\/)(app)/, '$1nz');
    }

    return <Auth />;
  }
}

export default RegionRedirect(Init);
