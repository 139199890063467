import React from 'react';
import P from 'prop-types';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import SelectDropdown from '$common/select-inputs/SelectDropdown';
import { searchPaths } from '$routes/pathHelpers';
import regionOptions, { fetchLocale } from './regionOptions';

export const IGNORE_REGION_GUESS_QUERY = 'ignoreRegionGuess=true';
export const IGNORE_REGION_GUESS = 'ignoreRegionGuess';

/**
 * build redirect url for region redirect but keep the same path and query parameters
 * @param {*} location global window location object
 * @param {*} domain redirect target domain
 */
export const singlePlatformRedirectUrlBuilder = (location, domain) => {
  if (location.search) {
    return `${domain}${location.pathname}${location.search}&${IGNORE_REGION_GUESS_QUERY}`;
  }

  return `${domain}${location.pathname}?${IGNORE_REGION_GUESS_QUERY}`;
};

/**
 * build region redirect url.
 * Region redirect happens on the web app in the below scenarios
 * - User first time landed on the web app (guess region happens based on user IP address)
 * - User changes region on the login page
 * - User changes region on the search page / group profile page / opportunity profile page when they are not logged in
 * @param {*} location global window location object.
 * @param {*} targetLocale locale of the website the customer is being redirected to. nz.becollective.com (nz)
 */
export const regionRedirectUrlBuilder = (location, targetLocale) => {
  const { domain } = regionOptions[targetLocale];
  // If current page is a group/opportunity/event/search page, redirect to the root search page
  // Because group/opportunity/event are region specific, and search parameter may not make sense in a different region(e.g. location)
  if (/^(\/groups)|^(\/events)|^(\/search)/.test(location.pathname)) {
    return `${domain}${searchPaths.opportunities()}?${IGNORE_REGION_GUESS_QUERY}`;
  }
  // For all other pages (login, register, quick-signup), change the domain but keep the same path and query parameters
  return singlePlatformRedirectUrlBuilder(location, domain);
};
const defaultLocale = fetchLocale();

const options = Object.keys(regionOptions).map((region) => {
  return {
    value: region,
    label: regionOptions[region].name,
  };
});

const defaultOption = options.find((option) => option.value === defaultLocale);

const StyledSelectDropdown = styled(SelectDropdown)`
  width: 100%;
`;

class RegionSelectorContainer extends React.Component {
  handleIntervalSelect =
    (location) =>
    ({ value }) => {
      window.location.assign(regionRedirectUrlBuilder(location, value, defaultLocale));
    };

  render() {
    const { location, transparentMode, darkFontMode } = this.props;

    return (
      <StyledSelectDropdown
        defaultOptions={options}
        defaultValues={defaultOption}
        disabled={false}
        clearable={false}
        searchable={false}
        multi={false}
        closeOnSelect
        allowCreate={false}
        onChange={this.handleIntervalSelect(location)}
        transparentMode={transparentMode}
        darkFontMode={darkFontMode}
        selectedOptionLabelTextAlign="left"
      />
    );
  }
}

RegionSelectorContainer.propTypes = {
  location: P.shape({
    pathname: P.string,
    search: P.string,
  }).isRequired,
  darkFontMode: P.bool,
  transparentMode: P.bool,
};

RegionSelectorContainer.defaultProps = {
  darkFontMode: false,
  transparentMode: false,
};

export default withRouter(RegionSelectorContainer);
