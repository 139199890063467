import React from 'react';
import P from 'prop-types';
import axios from 'axios';

import {
  IGNORE_REGION_GUESS_QUERY,
  IGNORE_REGION_GUESS,
  regionRedirectUrlBuilder,
} from '$common/region/RegionSelectorContainer';
import { fetchLocale } from '$common/region/regionOptions';
import { apply } from './apply';

export const CLIENT_REGION_KEY = 'clientRegion';
export const CLIENT_COUNTRY_CODE_KEY = 'clientCountryCode';

export const CLIENT_REGION_HEADER = 'x-client-bc-region';
export const CLIENT_COUNTRY_CODE_HEADER = 'x-client-country-code';

export const regionRedirectInRender = (ChildComponent) => {
  return class extends React.Component {
    static propTypes = {
      location: P.shape({}),
    };

    state = {
      shouldRenderPage: true,
    };

    async componentDidMount() {
      if (window.location.search.indexOf(IGNORE_REGION_GUESS_QUERY) > 0) {
        localStorage.setItem(IGNORE_REGION_GUESS, true);
        return;
      }

      if (localStorage.getItem(IGNORE_REGION_GUESS)) {
        return;
      }

      await this.fetchClientRegion()
        .then((clientRegion) => {
          const defaultLocale = fetchLocale();
          // *** Rule of redirection: ***
          // If user is visiting a region specific site, they should stay on that site
          // If user is visiting "Global site"(www.becollective.com), they should be redirected to the site of their region
          if (
            clientRegion === null ||
            defaultLocale === clientRegion ||
            // "Global site" (defaultLocale==='au')
            defaultLocale !== 'au'
          ) {
            this.renderPage();
          } else {
            window.location.assign(
              regionRedirectUrlBuilder(window.location, clientRegion.toLowerCase(), defaultLocale)
            );
          }
        })
        .catch(() => this.renderPage());
    }

    fetchClientRegion = () => {
      if (localStorage.getItem(CLIENT_REGION_KEY)) {
        return Promise.resolve(localStorage.getItem(CLIENT_REGION_KEY));
      }

      return axios.get('/ping').then((response) => {
        const clientRegionFromResponse = response.headers[CLIENT_REGION_HEADER];
        if (clientRegionFromResponse === null) {
          return null;
        }
        const clientRegion = clientRegionFromResponse === 'Global' ? 'au' : clientRegionFromResponse.toLowerCase();
        localStorage.setItem(CLIENT_REGION_KEY, clientRegion);

        const clientCountry = response.headers[CLIENT_COUNTRY_CODE_HEADER];
        localStorage.setItem(CLIENT_COUNTRY_CODE_KEY, clientCountry);

        return clientRegion;
      });
    };

    renderPage = () => {
      this.setState({
        shouldRenderPage: true,
      });
    };

    render() {
      const { shouldRenderPage } = this.state;

      if (shouldRenderPage) {
        return <ChildComponent />;
      }

      return null;
    }
  };
};

export default (ChildComponent) => apply([regionRedirectInRender])(ChildComponent);
