import React from 'react';
import P from 'prop-types';
import cx from 'classnames';

const LoadingBig = ({ className }) => {
  return (
    <div className={cx('loading-big', className)}>
      <div className="loading-big__inner">
        <div className="loading-big__ripple" />
        <div className="loading-big__ripple" />
        <div className="loading-big__ripple" />
      </div>
    </div>
  );
};

LoadingBig.propTypes = {
  className: P.string,
};

export default LoadingBig;
