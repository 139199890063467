import React from 'react';
import styled, { css } from 'styled-components';
import { generate } from 'shortid';
import P from 'prop-types';

const LabelWrapper = styled.label(
  ({ theme: { colors, media }, fluid, hoverable, flexDirection = 'row', disabled }) => css`
    width: ${fluid && '100%'};
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    padding: ${hoverable && '10px'};
    flex-direction: ${flexDirection};
    /* flex-direction: column doesn't work with small screens */
    ${!disabled &&
    `
      &:hover {
        cursor: pointer;
        background-color: ${hoverable && colors.alabaster};
      }
    `}
    ${disabled &&
    `
      color: ${colors.emperor}
      opacity: .5;
    `}
    ${media.breakpointDown(media.md)`
      flex-direction: row;
    `}
  `
);

const RadioInput = styled.input(
  ({ theme: { colors }, hasError, checkedColorName, disabled }) => css`
    /* Hide the native radio */
    opacity: 0.01;
    position: absolute;
    height: 1px;
    width: 1px;

    /* Custom styling */
    & + span {
      display: block;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      border: 1px solid ${hasError ? colors.roman : colors.dustyGray};
      background: ${colors.white};
    }

    &:checked + span {
      background: ${disabled ? colors.bombay : colors[checkedColorName]};
      &::after {
        content: '';
        position: relative;
        display: block;
        top: 4px;
        left: 4px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: white;
      }
    }

    &:focus + span {
      outline: 2px solid Highlight;
    }
  `
);

const ContentWrapper = styled.div(
  ({ flexDirection = 'row', theme: { media } }) => css`
    display: flex;
    align-items: center;
    margin-left: ${flexDirection === 'row' ? '8px' : 'initial'};
    text-align: ${flexDirection === 'row' ? 'initial' : 'center'};
    /* flex-direction: column doesn't work with small screens */
    ${media.breakpointDown(media.md)`
      margin-left: 8px;
      text-align: initial;
    `}

    font-family: AvenirLight, sans-serif;
    font-size: 14px;

    > :not(:last-child) {
      margin-right: 5px;
    }
  `
);

const RadioButton = (props) => {
  const {
    className,
    id,
    fluid,
    hoverable,
    label,
    title,
    imageEl,
    hasError,
    checked,
    checkedColorName,
    disabled,
    flexDirection,
    testAttr,
    ...rest
  } = props;

  const uniqueElementId = id || `radio-${generate()}`;

  const hasText = label || title;
  const hasContent = hasText || imageEl;

  return (
    <LabelWrapper
      className={className}
      htmlFor={uniqueElementId}
      fluid={fluid}
      hoverable={hoverable}
      flexDirection={flexDirection}
      disabled={disabled}
    >
      <RadioInput
        type="radio"
        id={uniqueElementId}
        {...rest}
        checked={checked}
        checkedColorName={checkedColorName}
        disabled={disabled}
        hasError={hasError}
        data-test={testAttr}
      />
      {/* span is the container for custom styling */}
      <span />
      {hasContent && (
        <ContentWrapper flexDirection={flexDirection}>
          {imageEl && <div>{imageEl}</div>}
          {hasText && (
            <div>
              {title && <b>{title}</b>}
              {label && <div>{label}</div>}
            </div>
          )}
        </ContentWrapper>
      )}
    </LabelWrapper>
  );
};

RadioButton.propTypes = {
  className: P.string,
  id: P.string,
  label: P.string,
  title: P.string,
  hasError: P.bool,
  fluid: P.bool,
  hoverable: P.bool,
  imageEl: P.element,
  checked: P.bool.isRequired,
  checkedColorName: P.string,
  disabled: P.bool,
  flexDirection: P.string,
  testAttr: P.string,
};

RadioButton.defaultProps = {
  hoverable: true,
  hasError: false,
  checkedColorName: 'gulfstream',
  disabled: false,
};

export default RadioButton;
