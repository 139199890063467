/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import P from 'prop-types';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';
import { find } from 'lodash';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import RadioButton from '$generic/RadioButton';
import Checkbox from '$common/Checkbox';
import colorThemes, { colorThemeNames } from '$theme/colorThemes';
import colorsTheme from '$theme/colors';
import zIndices from '$theme/zIndices';

const StyledCheckbox = styled(Checkbox)`
  display: inline-flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 8px;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRadio = styled(RadioButton)`
  display: inline-flex;
  margin-top: auto;
  margin-bottom: auto;
`;

const Dot = styled.span(
  ({ color }) => css`
    background-color: ${color};
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  `
);

const radioOption = (props) => {
  return (
    <components.Option {...props}>
      <StyledRadio
        fluid
        name="group"
        hoverable={false}
        checked={props.isSelected}
        label={props.data.label}
        imageEl={<Dot color={props.data.color} />}
      />
    </components.Option>
  );
};

radioOption.propTypes = {
  data: P.shape({
    value: P.string.isRequired,
    label: P.string.isRequired,
    color: P.string,
  }),
  isSelected: P.bool,
  isDisabled: P.bool,
  innerProps: P.shape({
    id: P.string,
  }),
  getValue: P.func,
};

// assumption: only supports multi
const checkboxOption = (props) => {
  return (
    <components.Option {...props}>
      <CheckboxWrapper>
        <StyledCheckbox
          isChecked={props.isSelected}
          onChange={()=>{}}
          id={props.innerProps.id}
          disableFocus
          disabled={props.isDisabled}
        />
        {props.data.label}
      </CheckboxWrapper>
    </components.Option>
  );
};

checkboxOption.propTypes = {
  data: P.shape({
    value: P.string.isRequired,
    label: P.string.isRequired,
  }),
  isSelected: P.bool,
  isDisabled: P.bool,
  innerProps: P.shape({
    id: P.string,
  }),
  getValue: P.func,
};

export const applyStylesOverrides = (defaultStyles, stylesOverrides) => {
  const controlKeys = Object.keys(defaultStyles);
  return controlKeys.reduce((result, controlKey) => {
    if (stylesOverrides[controlKey]) {
      result[controlKey] = (provided, state) => {
        return {
          ...defaultStyles[controlKey](provided, state),
          ...stylesOverrides[controlKey](provided, state),
        };
      };
    } else {
      result[controlKey] = defaultStyles[controlKey];
    }
    return result;
  }, {});
};

export const defaultStyles = {
  control: (provided, state) => {
    const { hideBorder, transparentMode, isFocused, menuIsOpen, isMulti, isDisabled } = state.selectProps;

    return {
      ...provided,
      justifyContent: (hideBorder || transparentMode) && !isMulti ? 'none' : 'space-between',
      borderWidth: hideBorder || transparentMode ? '0px' : '1px',
      borderColor: isFocused || menuIsOpen ? colorsTheme.gulfstream : colorsTheme.botticelli,
      '&:hover': {
        borderColor: colorsTheme.gulfstream,
      },
      pointerEvents: isDisabled ? 'none' : 'auto',
      border: hideBorder || transparentMode ? 'none' : `1px solid ${colorsTheme.botticelli}`,
      backgroundColor: transparentMode ? 'transparent !important' : provided.backgroundColor,
      boxShadow: transparentMode ? 'none' : provided.boxShadow,
    };
  },
  container: (provided, { isDisabled }) => {
    return {
      ...provided,
      pointerEvents: 'auto',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  clearIndicator: (provided) => {
    return provided;
  },
  dropdownIndicator: (provided, state) => {
    const { menuIsOpen, darkFontMode, transparentMode } = state.selectProps;

    const styles = { ...provided };

    if (menuIsOpen) {
      styles.transform = 'rotate(180deg)';
    }

    if (transparentMode) {
      styles.color = colorsTheme.white;
    }

    if (darkFontMode) {
      styles.color = colorsTheme.tundora;
    }

    return styles;
  },
  group: (provided) => {
    return provided;
  },
  groupHeading: (provided) => {
    return provided;
  },
  indicatorsContainer: (provided) => {
    return provided;
  },
  indicatorSeparator: (provided) => {
    return provided;
  },
  input: (provided) => {
    return {
      ...provided,
      border: 'none',
    };
  },
  loadingIndicator: (provided) => {
    return provided;
  },
  loadingMessage: (provided) => {
    return provided;
  },
  menu: (provided) => {
    return {
      ...provided,
      zIndex: `${zIndices.modal} !important`,
    };
  },
  menuList: (provided) => {
    return provided;
  },
  menuPortal: (provided) => {
    return provided;
  },
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: colorsTheme.gulfstream,
      borderColor: colorsTheme.gulfstream,
      color: colorsTheme.white,
    };
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: colorsTheme.white,
    };
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      padding: '3px 5px 1px 5px',
      borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
    };
  },
  noOptionsMessage: (provided) => {
    return provided;
  },
  option: (provided, { isDisabled, isFocused, isSelected }) => {
    return {
      ...provided,
      backgroundColor: isFocused ? '#f7f7f7' : 'none',
      fontFamily: isSelected ? 'AvenirHeavy' : 'AvenirLight',
      color: '#404040',
      outline: 'none',
      fontSize: '14px',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  placeholder: (provided) => {
    return provided;
  },
  singleValue: (provided, state) => {
    const { transparentMode, darkFontMode, hideBorder, isMulti } = state.selectProps;

    const style = { ...provided };
    if (transparentMode || darkFontMode) {
      style.color = darkFontMode ? colorsTheme.tundora : colorsTheme.white;
    }
    if ((hideBorder || transparentMode) && !isMulti) {
      style.position = 'relative';
      style.transform = 'none';
      style.overflow = 'visible';
    }
    return style;
  },
  valueContainer: (provided, state) => {
    const { selectedOptionLabelTextAlign, hideBorder, transparentMode, isMulti } = state.selectProps;
    let justifyContent = 'center';
    if (selectedOptionLabelTextAlign === 'left') {
      justifyContent = 'flex-start';
    } else if (selectedOptionLabelTextAlign === 'right') {
      justifyContent = 'flex-end';
    }

    return {
      ...provided,
      flexGrow: 1,
      flex: (hideBorder || transparentMode) && !isMulti && selectedOptionLabelTextAlign !== 'right' ? 'initial' : 1,
      justifyContent,
    };
  },
};

/*
 * General purpose multi select dropdown.
 * Options objects MUST have both value and label fields,
 * but can include any additional fields required as well.
 * Returns an array of selected items as objects.
 */
const SelectDropdown = ({
  className,
  multi,
  placeholder,
  allowCreate,
  allowAsyncSearchable,
  hideBorder,
  transparentMode,
  darkFontMode,
  closeOnSelect,
  clearable,
  disabled,
  searchable,
  selectedOptionLabelTextAlign,
  stylesOverride,
  componentsOverride,
  values,
  defaultValues,
  defaultOptions,
  onChange,
  showCheckbox,
  showRadio,
  afterRadio,
  testAttr,
  ...otherProps
}) => {
  /*
    Either values or defaultValues should be passed in - never both.
    If values is passed in, this will be a Controlled component (and an onChange is required).
    If defaultValues is passed in, this will be an Uncontrolled component (and an onChange isn't required).

    This is consistent with how React's form elements work (<input />, <textarea />, <select /> etc.)

    In any scenario where data is being managed outside of this component (eg. in Formik) or
    another component's state, you will most likely want this to be a Controlled component.
    This is by far the most common scenario.

    Only use <SelectDropdown /> as an Uncontrolled component deliberately and for good reason.
  */
  if (values && defaultValues) {
    console.warn(`
      SelectDropdown has both values and defaultValues set.
      This component should be explicitly either controlled(values) or uncontrolled(defaultValues)
    `);
  }

  // Component is Controlled by default.
  let value = values;
  let setValue = () => {};

  if (defaultValues) {
    // Set the component to Uncontrolled only if defaultValues exists
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [value, setValue] = useState(defaultValues);
  }

  const defaultComponents = {
    IndicatorSeparator: () => null,
    Option: (props) => {
      return (
        <components.Option {...props}>
          <div data-test={`${testAttr}-${props.label}`}>{props.children}</div>
        </components.Option>
      );
    },
  };

  // multi only option for now.
  if (showCheckbox && multi) {
    defaultComponents.Option = checkboxOption;
  }
  if (showRadio && !multi) {
    defaultComponents.Option = radioOption;
  }

  const selectProps = {
    className: classnames(className),
    name: 'multi-select',
    options: defaultOptions,
    defaultOptions: allowAsyncSearchable && defaultOptions,
    isMulti: multi,
    placeholder, // not documented?
    value,
    delimiter: '',
    closeMenuOnSelect: closeOnSelect,
    isClearable: clearable,
    noOptionsMessage: () => null,
    onChange: (val) => {
      const newValue = !val && multi ? [] : val;

      setValue(newValue);
      onChange(newValue);
    },
    isDisabled: disabled,
    isSearchable: searchable,
    components: {
      ...defaultComponents,
      ...componentsOverride,
    },
    selectedOptionLabelTextAlign,
    hideBorder,
    transparentMode,
    darkFontMode,
    styles: { ...applyStylesOverrides(defaultStyles, stylesOverride) },
    ...otherProps,
  };

  // Creatable cannot take an array of strings in defaultValues like Select can,
  // for the same functionality, use defaultValues={optionsCreator(values)}
  let SelectComponent;
  if (allowCreate) {
    SelectComponent = Creatable;
  } else if (allowAsyncSearchable) {
    SelectComponent = AsyncSelect;
  } else {
    SelectComponent = Select;
  }

  return (
    <div data-test={testAttr || 'SelectDropdown'} css="width: 100%;">
      <SelectComponent {...selectProps} />
    </div>
  );
};

SelectDropdown.propTypes = {
  stylesOverride: P.shape({}),
  componentsOverride: P.shape({}),
  className: P.string,
  placeholder: P.string,
  onChange: P.func,
  allowCreate: P.bool, // Allow custom tag/item creation
  allowAsyncSearchable: P.bool, // Allow searching with query
  query: P.shape({
    kind: P.oneOf(['Document']).isRequired, // prop-types doesn't seem to have equality
  }),
  queryVariables: P.shape({}),
  getQueryResults: P.func,
  multi: P.bool, // Allow multiple tag selection
  disabled: P.bool,
  closeOnSelect: P.bool,
  clearable: P.bool,
  searchable: P.bool,
  /**
   * The list options to be selected from, can be optionally split into sublists each with a label
   */
  defaultOptions: P.oneOfType([
    P.arrayOf(
      P.oneOfType([
        P.shape({
          value: P.any.isRequired,
          label: P.string.isRequired,
        }),
        P.shape({
          options: P.arrayOf(
            P.shape({
              value: P.any.isRequired,
              label: P.string.isRequired,
            })
          ),
          label: P.string.isRequired,
        }),
      ])
    ),
    P.bool,
  ]),
  /**
   * The selected option
   */
  defaultValues: P.oneOfType([
    P.arrayOf(
      P.shape({
        value: P.any.isRequired,
        label: P.string.isRequired,
      })
    ),
    P.shape({
      value: P.any.isRequired,
      label: P.string.isRequired,
    }),
  ]),
  values: P.oneOfType([
    P.arrayOf(
      P.shape({
        value: P.any.isRequired,
        label: P.string.isRequired,
      })
    ),
    P.shape({
      value: P.any.isRequired,
      label: P.string.isRequired,
    }),
  ]),
  hideBorder: P.bool,
  transparentMode: P.bool,
  darkFontMode: P.bool,
  selectedOptionLabelTextAlign: P.oneOf(['left', 'center', 'right']),
  /**
   * Color to be applied in border and background(with opacity=0.05)
   * Usage example: showing error status as form field
   */
  colorThemeName: P.oneOf(colorThemeNames),
  autoFocus: P.bool,
  backspaceRemovesValue: P.bool,
  controlShouldRenderValue: P.bool,
  hideSelectedOptions: P.bool,
  menuIsOpen: P.bool,
  tabSelectsValue: P.bool,
  showCheckbox: P.bool,
  showRadio: P.bool,
  afterRadio: P.func,
  testAttr: P.string,
};

SelectDropdown.defaultProps = {
  allowCreate: false,
  multi: true,
  placeholder: 'Select...',
  onChange: (val) => val,
  closeOnSelect: false,
  defaultOptions: [],
  defaultValues: null,
  transparentMode: false,
  darkFontMode: false,
  selectedOptionLabelTextAlign: 'left',
  stylesOverride: {},
  componentsOverride: {},
};

export default SelectDropdown;

// SelectDropdown helpers.

// TODO: Rename? formatConverter? turns array of strings or array of objects
// with id & name keys to the label & value format react-select requires.
export const optionsCreator = (items = [], labelFallBack = '') => {
  if (!items || !items.length) return [];
  if (typeof items[0] === 'string') {
    // Process array of strings
    return items.map((i) => ({ value: i, label: i || labelFallBack }));
  }
  if (typeof items[0] === 'number') {
    return items.map((i) => ({ value: i, label: i.toString() || labelFallBack }));
  }
  // Process array of objects with 'id' and 'name' keys.
  return items.map((i) => ({ value: i.id, label: i.name || labelFallBack }));
};

export const multiSelectResolver = (selectedList) => {
  return selectedList.map((s) => s.value);
};

/*
  selectedGetter:   Used to convert Array of Strings (id) to the
                    value & label format that react-select requires.
                    eg. Converting array of causeIds or skillIds strings.

  Input:
    allItems:       Array of Objects with 'id' and 'name' as keys.
                    [{
                      id: 'p0p0', name: 'Police Check',
                      id: 'drv3', name: 'Drivers License',
                      id: 'h3lp', name: 'First Aid',
                    }]
    selectedItems:  Array of Strings (id).
                    ['drv3']

  Output:           Array of Objects with 'value' and 'label' as keys.
                    [{ value: 'drv3', label: 'Drivers License' }]
*/
export const selectedGetter = (allItems, selectedItems) => {
  const validItems = selectedItems.filter((id) => !!find(allItems, { id }));

  return validItems.map((id) => ({
    value: find(allItems, { id }).id,
    label: find(allItems, { id }).name,
  }));
};

export const defaultValueGetter = (defaultOptions, isMulti, defaultValue) => {
  if (isMulti) {
    return defaultValue.map((value) => defaultOptions.find((o) => o.value === value));
  }

  return defaultOptions.find((x) => x.value === defaultValue);
};

/**
 * This named export is for the latest UI design
 * and is meant to be used for any new components in the platform.
 * The old style is kept in the default export just
 * to provide backwards-compatibility.
 */

export const StyledSelectDropdown = ({
  colorThemeName,
  maxWidth,
  height,
  darkInputText,
  iconPadding,
  testAttr,
  ...componentProps
}) => {
  const styles = {
    container: (provided) => {
      const containerStyles = {
        ...provided,
        fontSize: 14,
        backgroundColor: colorsTheme.white,
        borderRadius: 4,
        pointerEvents: 'auto',
      };
      if (maxWidth) {
        containerStyles.maxWidth = maxWidth;
      }
      if (height) {
        containerStyles.height = height;
      }
      if (darkInputText) {
        containerStyles.color = colorsTheme.tundora;
      }
      return containerStyles;
    },
    control: (provided, state) => {
      const controlStyles = {
        ...provided,
        flex: 1,
        border: `1px solid ${colorThemes[colorThemeName] || colorsTheme.botticelli}`,
        borderColor: `${
          colorThemes[colorThemeName] || (state.menuIsOpen ? colorsTheme.gulfstream : colorsTheme.botticelli)
        }`,
        // backgroundColor: `${
        //   colorThemeName ? transparentize(0.95, colorThemes[colorThemeName]) : provided.backgroundColor
        // }`,
        boxShadow: 'none',
        '&:hover': {
          borderColor: `${colorThemes[colorThemeName] || colorsTheme.gulfstream}`,
        },
      };
      if (iconPadding) {
        controlStyles.padding = iconPadding;
      }
      if (height) {
        controlStyles.height = height;
        controlStyles.minHeight = height;
      }
      return controlStyles;
    },
    valueContainer: (provided) => {
      const valueContainerStyles = {
        ...provided,
        height: '100%',
      };
      return valueContainerStyles;
    },
    indicatorsContainer: (provided) => {
      const indicatorsContainerStyles = {
        ...provided,
        height: '100%',
      };
      return indicatorsContainerStyles;
    },
    placeholder: (provided) => {
      const style = {
        ...provided,
        color: colorsTheme.doveGray,
      };
      if (iconPadding) {
        style.padding = iconPadding;
      }
      return style;
    },
    singleValue: (provided) => {
      const singleValueStyles = { ...provided, color: colorsTheme.doveGray };
      if (iconPadding) {
        singleValueStyles.padding = iconPadding;
      }
      if (darkInputText) {
        singleValueStyles.color = 'inherit';
      }
      return singleValueStyles;
    },
    input: (provided) => {
      const inputStyles = { ...provided };
      if (iconPadding) {
        inputStyles.padding = iconPadding;
      }
      return inputStyles;
    },
    dropdownIndicator: (provided) => {
      const style = {
        ...provided,
      };
      return style;
    },
  };

  return <SelectDropdown stylesOverride={styles} testAttr={testAttr} {...componentProps} />;
};

StyledSelectDropdown.propTypes = {
  colorThemeName: P.string,
  maxWidth: P.number,
  darkInputText: P.bool,
  iconPadding: P.string,
  testAttr: P.string,
};
