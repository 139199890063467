// Keep alphabetically sorted
// This tool is helpful https://wordcounter.net/alphabetize

// Color names from http://chir.ag/projects/name-that-color

const colors = {
  alabaster: '#F7F7F7',
  alto: '#D1D1D1',
  apricot: '#EC9060',
  apricotPeach: '#F8C7AF',
  athensGray: '#EDEFF2',
  black: '#000000',
  blueBayoux: '#476473',
  bombay: '#B0B3B7',
  botticelli: '#DFE4EF',
  catskillWhite: '#F3F6F9',
  chestnut: '#B24A4A',
  cornflowerBlue: '#91B9CD',
  como: '#486f62',
  dodgerBlue: '#3B99FC',
  doveGray: '#6F6F6F',
  dustyGray: '#969696',
  emperor: '#4F4F4F',
  edgeWater: '#BADACF',
  fuscousGray: '#58534F',
  gray: '#878787',
  gainsboro: '#E1E1E1',
  gallery: '#EDEDED',
  gulfstream: '#81BBA8',
  horizon: '#6091A9',
  iron: '#D3D5D7',
  killarney: '#40664D',
  mercury: '#E8E8E8',
  mintCream: '#F2F8F6',
  navy: '#29424D',
  nero: '#181818',
  lightazure: '#EFEFEF',
  oxley: '#709A8A',
  pampas: '#f7f7f2',
  patina: '#649B89',
  pixieGreen: '#C5DABF',
  porcelain: '#EBEDEF',
  puce: '#CD9199',
  rainPuddle: '#549c84',
  red: '#FF0000',
  roman: '#DF6262',
  ronchi: '#EBC35F',
  silverChalice: '#B1B1B1',
  snowGray: '#FAFAFA',
  tonysPink: '#E7A685',
  transparent: 'transparent',
  tundora: '#404040',
  white: '#FFFFFF',
  whisper: '#E4E4E4',
  wildSand: '#F6F6F6',
  yellow: 'yellow',
};

export default colors;

export const colorNames = Object.keys(colors);
