import colors from './colors';

const colorThemes = {
  danger: colors.roman,
  default: colors.botticelli,
  errorBackground: 'rgba(223, 98, 98, 0.05)',
  invalid: colors.bombay,
  success: colors.gulfstream,
  warning: colors.apricot,
};

export default colorThemes;

export const colorThemeNames = Object.keys(colorThemes);
