import { forEach, get, isObjectLike, isFunction, reduce } from 'lodash';

const css = 'color: red; font-weight: bold;';
export const argsInterpolater = (path, args = {}) => {
  let interpolatedPath = path;
  forEach(args, (value, argName) => {
    interpolatedPath = interpolatedPath.replace(`:${argName}`, value);
  });

  if (interpolatedPath.match(/:.+/)) {
    console.log('%cMissing args for path: %s', css, path);
    console.log('%cArgs supplied:', css, args);
    if (console.trace) console.trace();
  }
  return interpolatedPath;
};

const createPathHelpers = (routeMap) => {
  // Returns a map of route name -> function that resolves the path and interpolates args for
  // that route
  // Supports nested routes
  //
  // Example usage:
  // const routes = {
  //   update: '/events/:eventId/update' ,
  //   summary: '/events/manage/:eventId/summary',
  //   opportunity: {
  //     preview: '/events/:eventId/opportunities/:opportunityId/preview',
  //   },
  // }
  // const eventPaths = createPathHelpers(eventRoutes)
  // eventPaths.update({ eventId: params.eventId })
  // eventPaths.opportunity.preview({ eventId: event.id, opportunityId: event.opportunityId })
  //
  return reduce(
    routeMap,
    (routes, path, name) => {
      if (isObjectLike(path)) {
        routes[name] = createPathHelpers(path);
      } else if (isFunction(path)) {
        routes[name] = (args) => argsInterpolater(path(args), args);
      } else {
        routes[name] = argsInterpolater.bind(null, path);
      }
      return routes;
    },
    {}
  );
};

const traverseRouter = (routeComponent, pathArray, parentPath = '') => {
  // Finds every possible path available under a base routeComponent and inserts the full path
  // into the supplied pathArray
  // routeComponent can either be the root level <Router> or a <Route>
  //
  // This is intended as a debug/testing tool where we can check the paths defined in
  // each pathHelper with actual paths defined in our Routes. This lets us catch outdated
  // pathHelpers that might occur when a Route is updated but the developer forgets to also update
  // the pathHelper paths
  //
  // Example usage:
  // const paths = []
  // import Router from '$routes/Router';
  //
  // traverseRoutes(<Router />, paths)
  //   --->
  //   Traversed paths:
  //   /
  //   /admin
  //   /messenger
  //   /search
  //   /user/:userId
  //   /dashboard
  //   /dashboard/profile
  //   /dashboard/calendar
  //   /dashboard/social-record
  //   ....so on
  let currentPath = parentPath;
  if (get(routeComponent, 'props.path')) {
    currentPath = [parentPath, routeComponent.props.path].join('/').replace(/\/+/g, '/');
    pathArray.push(currentPath);
  }

  if (get(routeComponent, 'props.children')) {
    forEach(routeComponent.props.children, (childRoute) => {
      traverseRouter(childRoute, pathArray, currentPath);
    });
  }
};

export default createPathHelpers;
export { traverseRouter };
