import createPathHelpers from '$utils/routes';

export const commonPaths = createPathHelpers({
  root: '/',
  messenger: '/messenger',
  auth: '/auth',
  login: '/login',
  logout: '/logout',
  register: '/register',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
  admin: '/admin',
  userNotFound: '/errors/user-not-found',
  matchNotFound: '/errors/match-not-found',
});

export const userPaths = createPathHelpers({
  show: '/profiles/:userId',
  dashboard: {
    root: '/dashboard',
    profile: '/dashboard/profile',
    calendar: '/dashboard/calendar',
    socialRecord: '/dashboard/social-record',
    myVolunteering: {
      root: '/dashboard/my-volunteering',
      currentUpcoming: '/dashboard/my-volunteering/current-upcoming',
      pending: '/dashboard/my-volunteering/pending',
      invited: '/dashboard/my-volunteering/invited',
      declined: '/dashboard/my-volunteering/declined',
      past: '/dashboard/my-volunteering/past',
    },
    settings: {
      root: '/dashboard/settings',
      account: '/dashboard/settings/account',
      volunteerNotifications: '/dashboard/settings/notifications',
      managerNotifications: '/dashboard/settings/manager-notifications',
      privacy: '/dashboard/settings/privacy',
    },
    rewardsCertificates: {
      root: '/dashboard/rewards-certs',
      redeemed: '/dashboard/rewards-certs/redeemed-and-requested',
      declined: '/dashboard/rewards-certs/declined',
      certificates: '/dashboard/rewards-certs/certificates',
    },
    fundraising: {
      root: '/dashboard/fundraising',
      donations: '/dashboard/fundraising/donations',
      fundraisers: '/dashboard/fundraising/fundraisers',
    },
    familyGroup: {
      root: '/dashboard/family-group',
      members: '/dashboard/family-group/members',
      child: {
        root: '/dashboard/family-group/children/:memberId',
        generalActivity: {
          root: '/dashboard/family-group/children/:memberId/general-activity',
          all: '/dashboard/family-group/children/:memberId/general-activity/all',
          pending: '/dashboard/family-group/children/:memberId/general-activity/pending',
          approved: '/dashboard/family-group/children/:memberId/general-activity/approved',
          declined: '/dashboard/family-group/children/:memberId/general-activity/declined',
          notApplicable: '/dashboard/family-group/children/:memberId/general-activity/not-applicable',
        },
        qualifications: '/dashboard/family-group/children/:memberId/qualifications',
      },
      settings: '/dashboard/family-group/settings',
    },
    surveys: {
      root: '/dashboard/surveys',
      outstanding: '/dashboard/surveys/outstanding',
      responded: '/dashboard/surveys/responded',
    },
  },
});

export const groupPaths = {
  ...createPathHelpers({
    root: '/groups',
    show: '/groups/:groupId',
    profile: '/groups/:groupId/profile',
    opportunities: '/groups/:groupId/opportunities',
    events: '/groups/:groupId/events',
    partners: '/groups/:groupId/partners',
    articles: {
      root: '/groups/:groupId/articles',
      article: '/groups/:groupId/articles/:articleId',
    },
    docuLinks: {
      root: '/groups/:groupId/docu-links/list',
      document: '/groups/:groupId/docu-links/document',
      link: '/groups/:groupId/docu-links/link',
    },
  }),
  manage: createPathHelpers({
    root: '/manage/groups',
    show: '/manage/groups/:groupId',
    published: '/manage/groups/published',
    drafts: '/manage/groups/drafts',
    table: '/manage/groups/table',
    summary: '/manage/groups/:groupId/summary',
    details: '/manage/groups/:groupId/details',
    preview: '/manage/groups/:groupId/preview',
    fundraisers: '/manage/groups/:groupId/fundraisers',
    schedule: '/manage/groups/:groupId/schedule',
    scheduleOpportunity: '/manage/groups/:groupId/schedule/:opportunityId',
    eventSchedule: '/manage/groups/:groupId/schedule/events/:eventId',
    eventScheduleOpportunity: '/manage/groups/:groupId/schedule/events/:eventId/:opportunityId',
    endorsement: {
      root: '/manage/groups/:groupId/endorsements/:endorsementId',
      preview: '/manage/groups/:groupId/endorsements/:endorsementId/preview',
      timesheets: '/manage/groups/:groupId/endorsements/:endorsementId/timesheets',
      volunteers: '/manage/groups/:groupId/endorsements/:endorsementId/volunteers',
    },
    members: {
      root: '/manage/groups/:groupId/members',
      approved: '/manage/groups/:groupId/members/approved',
      pending: '/manage/groups/:groupId/members/pending',
      invited: '/manage/groups/:groupId/members/invited',
      declined: '/manage/groups/:groupId/members/declined',
      past: '/manage/groups/:groupId/members/past',
      count: '/groups/:groupId/members/count',
      manage: {
        root: '/manage/groups/:groupId/members/:memberId',
        details: '/manage/groups/:groupId/members/:memberId/details',
        applications: '/manage/groups/:groupId/members/:memberId/applications',
        activity: '/manage/groups/:groupId/members/:memberId/activity',
        qualifications: '/manage/groups/:groupId/members/:memberId/qualifications',
      },
    },
    volunteers: {
      root: '/manage/groups/:groupId/volunteers',
      active: '/manage/groups/:groupId/volunteers/approved',
      pending: '/manage/groups/:groupId/volunteers/pending',
      past: '/manage/groups/:groupId/volunteers/past',
      invited: '/manage/groups/:groupId/volunteers/invited',
      declined: '/manage/groups/:groupId/volunteers/declined',
      left: '/manage/groups/:groupId/volunteers/left',
    },
    opportunities: '/manage/groups/:groupId/opportunities',
    settings: {
      root: '/manage/groups/:groupId/settings',
      access: '/manage/groups/:groupId/settings/access',
      verify: '/manage/groups/:groupId/settings/verify',
      opportunitiesVisibility: '/manage/groups/:groupId/settings/opportunities-visibility',
      customFields: '/manage/groups/:groupId/settings/custom-fields',
      subscription: '/manage/groups/:groupId/settings/subscription',
    },
    reports: {
      root: '/manage/groups/:groupId/reports',
      analyticsHub: '/manage/groups/:groupId/reports/analytics-hub',
      overview: '/manage/groups/:groupId/reports/overview',
      engagement: '/manage/groups/:groupId/reports/engagement',
      export: '/manage/groups/:groupId/reports/export',
    },
    events: {
      root: '/manage/groups/:groupId/events',
      all: '/manage/groups/:groupId/events/all',
      current: '/manage/groups/:groupId/events/current',
      past: '/manage/groups/:groupId/events/past',
      drafts: '/manage/groups/:groupId/events/drafts',
      cancelled: '/manage/groups/:groupId/events/cancelled',
    },
    docuLinks: {
      list: {
        root: '/manage/groups/:groupId/docu-links/list',
        document: '/manage/groups/:groupId/docu-links/list/document',
        link: '/manage/groups/:groupId/docu-links/list/link',
      },
      create: {
        root: '/manage/groups/:groupId/docu-links/create',
        document: '/manage/groups/:groupId/docu-links/create/document',
        link: '/manage/groups/:groupId/docu-links/create/link',
      },
    },
    partnerships: {
      root: '/manage/groups/:groupId/partnerships',
      approved: '/manage/groups/:groupId/partnerships/approved',
      pending: '/manage/groups/:groupId/partnerships/pending',
      rejected: '/manage/groups/:groupId/partnerships/rejected',
      cancelled: '/manage/groups/:groupId/partnerships/cancelled',
    },
    articles: {
      root: '/manage/groups/:groupId/articles',
      edit: '/manage/groups/:groupId/articles/:articleId/edit',
      new: '/manage/groups/:groupId/articles/new',
    },
    surveyCampaigns: {
      root: '/manage/groups/:groupId/surveys',
      campaignManage: '/manage/groups/:groupId/surveys/:campaignId',
      surveyManage: '/manage/groups/:groupId/surveys/:campaignId/:surveyId',
      participantManage: '/manage/groups/:groupId/surveys/:campaignId/:surveyId/participants/:id',
    },
    modules: {
      root: '/manage/groups/:groupId/modules',
      socialCurrency: {
        root: '/manage/groups/:groupId/modules/rewards-and-certificates',
        rewards: {
          root: '/manage/groups/:groupId/modules/rewards-and-certificates/rewards',
          active: '/manage/groups/:groupId/modules/rewards-and-certificates/rewards/active',
          expired: '/manage/groups/:groupId/modules/rewards-and-certificates/rewards/expired',
          drafts: '/manage/groups/:groupId/modules/rewards-and-certificates/rewards/drafts',
          create: '/manage/groups/:groupId/modules/rewards-and-certificates/rewards/:rewardId/new',
          edit: '/manage/groups/:groupId/modules/rewards-and-certificates/rewards/:rewardId/edit',
        },
        redemptions: {
          root: '/manage/groups/:groupId/modules/rewards-and-certificates/redemptions',
          approved: '/manage/groups/:groupId/modules/rewards-and-certificates/redemptions/approved',
          pending: '/manage/groups/:groupId/modules/rewards-and-certificates/redemptions/pending',
          rejected: '/manage/groups/:groupId/modules/rewards-and-certificates/redemptions/rejected',
          cancelled: '/manage/groups/:groupId/modules/rewards-and-certificates/redemptions/cancelled',
        },
        certificates: {
          root: '/manage/groups/:groupId/modules/rewards-and-certificates/certificates',
        },
        issuedCertificates: {
          root: '/manage/groups/:groupId/modules/rewards-and-certificates/issued-certificates',
        },
      },
      donations: {
        root: '/manage/groups/:groupId/modules/donations',
        manage: '/manage/groups/:groupId/modules/donations/manage',
        settings: '/manage/groups/:groupId/modules/donations/settings',
        group: {
          root: '/manage/groups/:groupId/modules/donations/group',
          reporting: '/manage/groups/:groupId/modules/donations/group/reporting',
          settings: '/manage/groups/:groupId/modules/donations/group/settings',
        },
        event: {
          root: '/manage/groups/:groupId/modules/donations/event/:eventId',
          reporting: '/manage/groups/:groupId/modules/donations/event/:eventId/reporting',
          settings: '/manage/groups/:groupId/modules/donations/event/:eventId/settings',
        },
      },
    },
  }),
  wizard: {
    edit: ({ groupId }) => `/manage/groups/table?modal=GroupWizard&groupId=${groupId}`,
  },
};

export const eventPaths = {
  ...createPathHelpers({
    root: '/events',
    show: '/events/:eventId',
    profile: '/events/:eventId/profile',
    opportunities: '/events/:eventId/opportunities',
  }),
  manage: createPathHelpers({
    root: '/manage/events',
    all: '/manage/events/all',
    current: '/manage/events/current',
    past: '/manage/events/past',
    drafts: '/manage/events/drafts',
    cancelled: '/manage/events/cancelled',
    show: '/manage/events/:eventId',
    edit: '/manage/events/:eventId/edit',
    create: '/manage/events/:eventId/new',
    summary: '/manage/events/:eventId/summary',
    details: '/manage/events/:eventId/details',
    volunteers: '/manage/events/:eventId/volunteers',
    calendar: '/manage/events/:eventId/calendar',
    opportunities: '/manage/events/:eventId/opportunities',
    schedule: '/manage/groups/:groupId/schedule/events/:eventId',
  }),
};

// Resolve nested opportunity management routes
const managementPrefix = (suffix) => {
  return (params) => {
    if (!params) return suffix;

    let path = '';
    if (params.eventId) path = `/manage/events/${params.eventId}`;
    else if (params.groupId) path = `/manage/groups/${params.groupId}`;

    if (params.opportunityId) {
      path = `${path}/opportunities/${params.opportunityId}`;
    }

    return `${path}${suffix}`;
  };
};

// Resolve nested opportunity user view routes
const dashboardPrefix = (suffix) => {
  return (params) => {
    if (!params) return suffix;

    let path = '';
    if (params.eventId) path = `/events/${params.eventId}`;
    else if (params.groupId) path = `/groups/${params.groupId}`;

    return `${path}${suffix}`;
  };
};
export const opportunityPaths = createPathHelpers({
  root: '/opportunities',
  all: dashboardPrefix('/opportunities'),
  show: dashboardPrefix('/opportunities/:opportunityId'),
  details: dashboardPrefix('/opportunities/:opportunityId/details'),
  volunteerBrief: dashboardPrefix('/opportunities/:opportunityId/volunteer-brief'),
  manage: {
    all: managementPrefix('/opportunities/all'),
    active: managementPrefix('/opportunities/active'),
    complete: managementPrefix('/opportunities/complete'),
    drafts: managementPrefix('/opportunities/drafts'),
    cancelled: managementPrefix('/opportunities/cancelled'),
    endorsed: managementPrefix('/opportunities/endorsed'),
    show: managementPrefix(''),
    message: managementPrefix('/message'),
    invite: managementPrefix('/invite'),
    create: managementPrefix('/wizard'),
    edit: managementPrefix('/wizard'),
    preview: managementPrefix('/preview'),
    volunteerBrief: managementPrefix('/volunteer-brief'),
    timesheets: {
      root: managementPrefix('/timesheets'),
      approved: managementPrefix('/timesheets/approved'),
      pending: managementPrefix('/timesheets/pending'),
      noshows: managementPrefix('/timesheets/noshows'),
    },
    volunteers: {
      root: managementPrefix('/volunteers'),
      assign: managementPrefix('/volunteers/:volunteerId/assign'),
      active: managementPrefix('/volunteers/active'),
      past: managementPrefix('/volunteers/past'),
      invited: managementPrefix('/volunteers/invited'),
      approved: managementPrefix('/volunteers/approved'),
      pending: managementPrefix('/volunteers/pending'),
      declined: managementPrefix('/volunteers/declined'),
      left: managementPrefix('/volunteers/left'),
    },
    endorsements: {
      root: managementPrefix('/endorsements'),
      endorsers: managementPrefix('/endorsements/endorsers'),
      pending: managementPrefix('/endorsements/pending'),
      declined: managementPrefix('/endorsements/declined'),
    },
  },
});

export const sharePaths = createPathHelpers({
  group: '/share/groups/:groupId',
  event: '/share/events/:eventId',
  user: '/share/users/:userId',
  opportunity: '/share/opportunities/:opportunityId',
  article: '/share/articles/:articleId',
});

export const searchPaths = createPathHelpers({
  opportunities: '/search/opportunities',
  groups: '/search/groups',
  events: '/search/events',
  users: '/search/users',
});
