export default {
  auto: 'auto',
  abovePage: 1,
  aboveCalendar: 4,
  selectDropdownWithSearch: 5,
  nav: 5,
  popupOverlay: 5,
  popup: 6,
  modalOverlay: 7,
  modal: 8,
  nagBar: 8,
};
