import React from 'react';
import P from 'prop-types';
import styled, { css } from 'styled-components';

import { generate } from 'shortid';

const CheckboxContainer = styled.label(
  ({ theme: { colors }, disableFocus, secondaryStyle }) => css`
    position: relative;
    margin: 0;

    /* Hide native checkbox
      Do not use display: none since this will remove ability to tab (focus) through the inputs
    */
    input {
      opacity: 0;
      position: absolute;
      height: 0;
      width: 0;
    }

    /* Background, enabled, unchecked */
    input + span {
      display: block;
      height: 16px;
      width: 16px;
      border-radius: 2px;
      border: 1px solid ${colors.botticelli};
      background: ${colors.white};

      &:hover {
        cursor: pointer;
      }
    }

    /* Checked */
    input:checked + span {
      border: 1px solid ${secondaryStyle ? colors.botticelli : colors.gulfstream};
      background: ${secondaryStyle ? colors.white : colors.gulfstream};

      &::after {
        content: '';
        position: absolute;
        left: 4px;
        top: 4px;
        height: 6px;
        width: 9px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-45deg);
        color: ${secondaryStyle ? colors.gulfstream : colors.white};
      }
    }

    /* Focused */
    input:focus + span {
      outline: ${disableFocus ? 'none' : 'rgb(59, 153, 252) auto 5px'};
    }

    /* Disabled */
    input:disabled + span {
      background: ${colors.alto};
      border: none;

      &:hover {
        cursor: not-allowed;
      }
    }
  `
);

const Checkbox = ({ id, isChecked, onChange, disabled, className, disableFocus, secondaryStyle, testAttr }) => {
  const uniqueElementId = id || `checkbox-${generate()}`;

  return (
    <CheckboxContainer
      className={className}
      htmlFor={uniqueElementId}
      disableFocus={disableFocus}
      secondaryStyle={secondaryStyle}
      data-test={testAttr}
    >
      <input checked={isChecked} onChange={onChange} type="checkbox" id={uniqueElementId} disabled={disabled} />
      {/* <span> is used for the custom styling */}
      <span />
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  id: P.string,
  isChecked: P.bool,
  onChange: P.func,
  disabled: P.bool,
  className: P.string,
  testAttr: P.string,
  disableFocus: P.bool,
  secondaryStyle: P.bool,
};

Checkbox.defaultPropers = {
  disableFocus: false,
};

export default Checkbox;
