import 'becollectivePolyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import Init from './components/Init';
import '../stylesheets/app.scss';
import '../../public/fonts/webfonts.css';
import '../../public/css/icons.css';

// Instrumentation should initialise before any other code
datadogRum.init({
  applicationId: '344e84dc-3a1c-4c61-b0d2-f02c9e5d9a85',
  clientToken: 'pub9f7d162cfbcaa830bb7c93051737d410',
  site: 'datadoghq.eu',
  service: 'Web',
  env: process.env.NODE_ENV,
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
});

// LogRocket
if (LOGROCKET_KEY) {
  LogRocket.init(LOGROCKET_KEY, {
    release: process.env.LOGROCKET_RELEASE,
    network: {
      requestSanitizer: (request) => {
        if (!request.body || typeof request.body !== 'string') return request;

        const parsedBody = JSON.parse(request.body);

        const PASSWORD_MUTATIONS = [
          'ChangePasswordMutation',
          'ForcePasswordChangeMutation',
          'ForgotPasswordMutation',
          'LoginMutation',
          'RegisterMutation',
          'ResetPasswordMutation',
          'SetGhostPasswordMutation',
          'UseGhostRegistrationKey',
        ];

        if (parsedBody.operationName && PASSWORD_MUTATIONS.includes(parsedBody.operationName)) {
          const obfuscatedInput = {};
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(parsedBody.variables.input)) {
            if (key.toLowerCase().includes('password')) {
              obfuscatedInput[key] = '*****';
            }
            else {
              obfuscatedInput[key] = value;
            }
          }
          parsedBody.variables.input = obfuscatedInput;

          request.body = JSON.stringify(parsedBody);
        }

        return request;
      },
    },
  });
}
setupLogRocketReact(LogRocket);

// Perform any setup/initialization and then render application
export const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Init />);

if (module.hot) {
  module.hot.accept();
}
