import { omit, pull, concat } from 'lodash';
import { locales, languages } from '@becollective/constants';

const { regionOptions } = locales;

export const fetchLocale = () => {
  const { hostname } = window.location;
  switch (hostname) {
    // case regionOptions.eu.hostname:
    //   return 'eu';
    case regionOptions.nz.hostname:
      return 'nz';
    case regionOptions.uk.hostname:
      return 'uk';
    default:
      return 'au';
  }
};

export const fetchPlatform = () => {
  return process.env.NODE_ENV;
};

export const fetchOtherRegionTitles = (currentRegion) => {
  return Object.keys(omit(regionOptions, currentRegion)).map((regionOption) => regionOptions[regionOption].name);
};

export const fetchSortedLanguages = () => {
  const locale = fetchLocale();
  const nativeLanguages = [];
  if (locale === 'nz') {
    nativeLanguages.push('english', 'te reo māori');
  } else if (locale === 'uk') {
    nativeLanguages.push('english', 'irish', 'scottish gaelic', 'welsh');
  } else if (locale === 'au') {
    nativeLanguages.push('english');
  }
  return concat(nativeLanguages, pull(languages, ...nativeLanguages));
};

export default regionOptions;
